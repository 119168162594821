/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { EditConstellationMenuItem_ConstellationFragmentDoc } from './contents/edit-constellation-menu-item/EditConstellationMenuItem.generated';
import { DownloadNovaMenuItem_NovaFragmentDoc } from './contents/download-nova-menu-item/DownloadNovaMenuItem.generated';
export type ConstellationContextMenu_ConstellationFragment = { id: any, isArchived: boolean, name: string, color: string, organizationId: any | null, constellationContextMenu_constellation_novasCollection: { edges: Array<{ node: { id: any, name: string, embeddedLink: string | null, novaType: { id: any, code: string } | null } }> } | null, icons: { iconDefinition: any } | null, EditConstellationModal_Novas: { edges: Array<{ node: { id: any, embeddedLink: string | null, novaType: { code: string } | null, novaDecorationImage: { id: any, imageUrl: string | null } | null } }> } | null };

export const ConstellationContextMenu_ConstellationFragmentDoc = `
    fragment ConstellationContextMenu_Constellation on Constellations {
  id
  isArchived
  ...EditConstellationMenuItem_Constellation
  constellationContextMenu_constellation_novasCollection: novasCollection(
    filter: {isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
        novaType {
          id
          code
        }
        ...DownloadNovaMenuItem_Nova
      }
    }
  }
}
    ${EditConstellationMenuItem_ConstellationFragmentDoc}
${DownloadNovaMenuItem_NovaFragmentDoc}`;