import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CreateUseDataOptions, DataHook } from "./create-use-data.types";
import { hashArgs } from "./create-use-data.utils";
import { CreateUseDataContext } from "./create-use-data.provider";

export const createUseData =
    <D, A extends unknown[]>(
        dataGetter: (...args: A) => Promise<D>,
        options?: CreateUseDataOptions
    ): DataHook<D, A> => (
        (...args: A) => {

            // context
            const { cachedData, setCachedData } = useContext(CreateUseDataContext)

            // refs
            const currentArgsHashRef = useRef<string>()

            // state
            const [isLoading, setIsLoading] = useState(false)
            const [didError, setDidError] = useState(false)
            const [data, setData] = useState<D | null>(null)

            // imperative logic
            const retrieveData = useCallback(async (...requestArgs: A) => {
                const requestArgsHash = hashArgs(requestArgs);
                const cacheKey = `${options?.caching?.identifier}_${requestArgsHash}`

                if (options?.caching?.enable && cachedData[cacheKey]) {
                    setIsLoading(false)
                    setDidError(false)
                    setData(cachedData[cacheKey])
                    return;
                }
                
                setIsLoading(true)
                setDidError(false)

                let result: D | undefined = undefined;
                let error: unknown;
                try {
                    result = await dataGetter(...requestArgs)
                } catch (err) {
                    error = err;
                }

                if (requestArgsHash !== currentArgsHashRef.current) return;

                if (error || !result) {
                    setDidError(true)
                    setData(null)
                    setIsLoading(false)
                    return
                }

                setData(result)
                setIsLoading(false)

                if (options?.caching?.enable) {
                    setCachedData(prevCachedData => ({
                        ...prevCachedData,
                        [cacheKey]: result,
                    }))
                }
            }, [cachedData, setCachedData])

            // side effects
            useEffect(() => {
                currentArgsHashRef.current = hashArgs(args);
                retrieveData(...args)
            // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [...args])

            // event handlers
            const handleRefreshData = useCallback(() => {
                currentArgsHashRef.current = hashArgs(args);
                retrieveData(...args)
            }, [args, retrieveData])

            const handleReset = useCallback(() => {
                setIsLoading(false)
                setDidError(false)
                setData(null)
            }, [])

            return {
                data,
                isLoading,
                didError,
                refreshData: handleRefreshData,
                reset: handleReset,
            }
        }
    )