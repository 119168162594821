import Button from 'antd/es/button'
import React, { FC, useCallback, useState } from 'react'
import { AddUserToOrganizationDialog } from '../add-user-to-organization-dialog/add-user-to-organization-dialog'
import { AddOrganizationMemberButton_OrganizationFragment } from './AddOrganizationMemberButton.generated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'

type AddOrganizationMemberButtonProps = {
	organization: AddOrganizationMemberButton_OrganizationFragment
}

export const AddOrganizationMemberButton: FC<AddOrganizationMemberButtonProps> = ({
	organization,
}) => {

	// state
	const [dialogOpen, setDialogOpen] = useState<boolean>(false)

	// event handlers
	const handleAddUserButtonClick = useCallback(() => {
		setDialogOpen(true)
	}, [])

	const handleDialogClose = useCallback(() => {
		setDialogOpen(false)
	}, [])

	return (
		<>
			<Button type='primary' icon={<FontAwesomeIcon icon={faPlus} />} onClick={handleAddUserButtonClick}>
				Add User(s)
			</Button>
			<AddUserToOrganizationDialog
				organization={organization}
				open={dialogOpen}
				onDismiss={handleDialogClose}
			/>
		</>
	)
}