/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../../../common/graphql/generated/types';

import { GroupContextMenu_GroupFragmentDoc } from '../../../../../../../groups/common/group-context-menu/GroupContextMenu.generated';
import { GroupDragReceiver_GroupFragmentDoc } from '../../../../../../../groups/common/group-drag-receiver/group-drag-receiver.generated';
export type GroupNavButton_GroupFragment = { id: any, name: string, icon: string, organizationId: any | null, parentGroupId: any | null, isArchived: boolean, color: string, parentGroup: { id: any, name: string } | null, icons: { iconDefinition: any } | null, editGroupAccessDialog_group_groupMembersCollection: { edges: Array<{ node: { id: any, groupId: any | null, userId: any | null, user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null, addGroupMemberForm_group_groupMembersCollection: { edges: Array<{ node: { id: any, userId: any | null } }> } | null, organization: { id: any, addGroupMemberForm_group_organizationMembersCollection: { edges: Array<{ node: { user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null } | null };

export const GroupNavButton_GroupFragmentDoc = `
    fragment GroupNavButton_Group on Groups {
  id
  name
  icon
  organizationId
  parentGroupId
  isArchived
  ...GroupContextMenu_Group
  ...GroupDragReceiver_Group
}
    ${GroupContextMenu_GroupFragmentDoc}
${GroupDragReceiver_GroupFragmentDoc}`;