import { useQueryClient } from '@tanstack/react-query'
import Menu from 'antd/es/menu'
import message from 'antd/es/message'
import Modal from 'antd/es/modal'
import React, { FC, useCallback, useContext } from 'react'
import { queryInvalidationHelper } from '../../../../../common/react-query/query-invalidation.helper'
import { AppEventContext } from '../../../../app/providers/app-event-provider/AppEventProvider'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { service } from './cancel-organization-member-invitation-menu-item.service'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'

type CancelOrganizationMemberInvitationMenuItemProps = {
	organizationMemberInvitationId: string
	onClose: () => void
}

export const CancelOrganizationMemberInvitationMenuItem: FC<CancelOrganizationMemberInvitationMenuItemProps> = ({
	organizationMemberInvitationId,
	onClose,
}) => {

	// query client
	const queryClient = useQueryClient()

	// context
	const { logAppEvent } = useContext(AppEventContext)

	// event handlers
	const handleRemoveUserClicked = useCallback(() => {
		onClose()
		Modal.confirm({
			icon: <FontAwesomeIcon icon={faExclamationCircle} />,
			content: "Are you sure you want to cancel this invitation?",
			onOk: async () => {
				/* delete the invitation */
				const result = await service.cancelInvitation(organizationMemberInvitationId)

				/* handle any errors that occurred */
				if (!result.success || !result.organizationMemberInvitation) {
					message.error('Something went wrong. Please try again.')
					return;
				}

				/* invalidate relevant queries */
				queryClient.invalidateQueries(
					queryInvalidationHelper([
						{ organizationMemberInvitationId: result.organizationMemberInvitation.id },
						{ organizationId: result.organizationMemberInvitation.invitedToOrganizationId },
						{ userId: result.organizationMemberInvitation.invitedUserId },
					])
				)

				/* log an app event */
				await logAppEvent('cancelled_organization_member_invitation', {
					organizationMemberInvitationId,
				})

				/* inform the user that the request succeeded */
				message.success('Successfully cancelled invitation')
			},
			okType: 'danger',
			okText: 'Yes, I\'m sure',
		})
	}, [logAppEvent, onClose, organizationMemberInvitationId, queryClient])

	return (
		<Menu.Item
			danger
			icon={<FontAwesomeIcon icon={faTimes} />}
			onClick={handleRemoveUserClicked}
		>
			Cancel Invitation
		</Menu.Item>
	)
}