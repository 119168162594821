import React, { createContext, FC, } from 'react'
import { TypesProvider_ActionTypeFragment, TypesProvider_AliasTypeFragment, TypesProvider_AppEventTypeFragment, TypesProvider_GroupMemberTypeFragment, TypesProvider_NovaTypeFragment, TypesProvider_OrganizationMemberRoleFragment, TypesProvider_ScheduleTypeFragment, useInfiniteTypesProvider_TypesQuery } from './TypesProvider.generated'
import { useFetchAllPages, useNodes } from './TypesProvider.hooks'

type TypesProviderProps = {
    children: React.ReactNode
}

export const TypesProvider: FC<TypesProviderProps> = ({ children }) => {

    // data
    const {
        data,
        isLoading,
        isFetching,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteTypesProvider_TypesQuery(
        'actionTypesCursor', // not used, but needed because of auto generator
        {
            actionTypesCursor: null,
            aliasTypesCursor: null,
            appEventTypesCursor: null,
            groupMemberTypesCursor: null,
            novaTypesCursor: null,
            scheduleTypesCursor: null,
            organizationMemberRolesCursor: null,
        },
        {
            staleTime: Infinity,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage) => {
                if (
                    !lastPage.actionTypesCollection?.pageInfo.hasNextPage
                    && !lastPage.aliasTypesCollection?.pageInfo.hasNextPage
                    && !lastPage.appEventTypesCollection?.pageInfo.hasNextPage
                    && !lastPage.groupMemberTypesCollection?.pageInfo.hasNextPage
                    && !lastPage.groupMemberTypesCollection?.pageInfo.hasNextPage
                    && !lastPage.novaTypesCollection?.pageInfo.hasNextPage
                    && !lastPage.scheduleTypesCollection?.pageInfo.hasNextPage
                    && !lastPage.organizationMemberRolesCollection?.pageInfo.hasNextPage
                ) return undefined;

                return ({
                    actionTypesCursor: lastPage.actionTypesCollection?.pageInfo.endCursor,
                    aliasTypesCursor: lastPage.aliasTypesCollection?.pageInfo.endCursor,
                    appEventTypesCursor: lastPage.appEventTypesCollection?.pageInfo.endCursor,
                    groupMemberTypesCursor: lastPage.groupMemberTypesCollection?.pageInfo.endCursor,
                    novaTypesCursor: lastPage.novaTypesCollection?.pageInfo.endCursor,
                    scheduleTypesCursor: lastPage.scheduleTypesCollection?.pageInfo.endCursor,
                    organizationMemberRolesCursor: lastPage.organizationMemberRolesCollection?.pageInfo.endCursor,
                })
            }
        }
    )

    useFetchAllPages({
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    })

    // extract the types
    const actionTypes = useNodes(data, 'actionTypesCollection')
    const aliasTypes = useNodes(data, 'aliasTypesCollection')
    const appEventTypes = useNodes(data, 'appEventTypesCollection')
    const groupMemberTypes = useNodes(data, 'groupMemberTypesCollection')
    const novaTypes = useNodes(data, 'novaTypesCollection')
    const scheduleTypes = useNodes(data, 'scheduleTypesCollection')
    const organizationMemberRoles = useNodes(data, 'organizationMemberRolesCollection')

    return (
        <TypesContext.Provider value={{
            isLoading,
            isFetching,
            actionTypes,
            aliasTypes,
            appEventTypes,
            groupMemberTypes,
            novaTypes,
            scheduleTypes,
            organizationMemberRoles,
        }}>
            {children}
        </TypesContext.Provider>
    )
}

export type TypesContextType = {
    isLoading: boolean
    isFetching: boolean
    actionTypes: TypesProvider_ActionTypeFragment[] | null
    aliasTypes: TypesProvider_AliasTypeFragment[] | null
    appEventTypes: TypesProvider_AppEventTypeFragment[] | null
    groupMemberTypes: TypesProvider_GroupMemberTypeFragment[] | null
    novaTypes: TypesProvider_NovaTypeFragment[] | null
    scheduleTypes: TypesProvider_ScheduleTypeFragment[] | null
    organizationMemberRoles: TypesProvider_OrganizationMemberRoleFragment[] | null
}

export const TypesContext = createContext<TypesContextType>({
    isLoading: true,
    isFetching: false,
    actionTypes: null,
    aliasTypes: null,
    appEventTypes: null,
    groupMemberTypes: null,
    novaTypes: null,
    scheduleTypes: null,
    organizationMemberRoles: null,
})