import { useQueryClient } from '@tanstack/react-query'
import Menu from 'antd/es/menu'
import message from 'antd/es/message'
import Modal from 'antd/es/modal'
import React, { FC, useCallback, useContext } from 'react'
import { queryInvalidationHelper } from '../../../../../common/react-query/query-invalidation.helper'
import { AppEventContext } from '../../../../app/providers/app-event-provider/AppEventProvider'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { service } from './remove-member-menu-item.service'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'

type RemoveMemberMenuItemProps = {
	organizationMemberId: string
	onClose: () => void
}

export const RemoveMemberMenuItem: FC<RemoveMemberMenuItemProps> = ({
	organizationMemberId,
	onClose,
}) => {

	// query client
	const queryClient = useQueryClient()

	// context
	const { logAppEvent } = useContext(AppEventContext)

	// event handlers
	const handleRemoveUserClicked = useCallback(() => {
		onClose()
		Modal.confirm({
			icon: <FontAwesomeIcon icon={faExclamationCircle} />,
			content: "Are you sure you want to remove this member from this organization?",
			onOk: async () => {
				/* remove the member */
				const result = await service.removeMember(organizationMemberId)

				/* handle any errors that occurred */
				if (!result.success || !result.organizationMember) {
					message.error('Something went wrong. Please try again.')
					return;
				}

				/* invalidate relevant queries */
				queryClient.invalidateQueries(
					queryInvalidationHelper([
						{ userId: result.organizationMember.userId },
						{ organizationId: result.organizationMember.organizationId },
					])
				)

				/* log an app event */
				await logAppEvent('removed_user_from_organization', {
					organizationMemberId: result.organizationMember?.id,
				})

				/* inform the user that the request succeeded */
				message.success('Successfully removed user from Enterprise')
			},
			okType: 'danger',
			okText: 'Yes, I\'m sure',
		})
	}, [logAppEvent, onClose, organizationMemberId, queryClient])

	return (
		<Menu.Item
			danger
			icon={<FontAwesomeIcon icon={faTimes} />}
			onClick={handleRemoveUserClicked}
		>
			Remove Member
		</Menu.Item>
	)
}