import React, { FC } from 'react';
import './App.less';
import './modules/app/globals';
import { AppRouter } from './modules/app/components/app-router/app-router';
import { AuthenticationProvider } from './modules/app/providers/authentication-provider/AuthenticationProvider';
import { ReactQueryProvider } from './modules/app/providers/react-query-provider/ReactQueryProvider';
import { DialogsProvider } from './modules/common/dialogs-provider/DialogsProvider';
import { MessageConfigProvider } from './modules/app/providers/message-config-provider/message-config-provider';
import { DragAndDropProvider } from './modules/app/providers/drag-and-drop-provider/drag-and-drop-provider';
import { ErrorBoundary } from './modules/app/components/error-boundary/error-boundary';
import { CreateUseDataProvider } from './common/hooks/create-use-data/create-use-data.provider';

const App: FC = () => {
  return (
    <ErrorBoundary>
      <ReactQueryProvider>
        <CreateUseDataProvider>
          <DragAndDropProvider>
            <MessageConfigProvider>
              <AuthenticationProvider>
                <DialogsProvider>
                  <AppRouter />
                </DialogsProvider>
              </AuthenticationProvider>
            </MessageConfigProvider>
          </DragAndDropProvider>
        </CreateUseDataProvider>
      </ReactQueryProvider>
    </ErrorBoundary>
  );
}

export default App;
