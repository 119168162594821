/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { EditGroupMenuItem_GroupFragmentDoc } from './contents/edit-group-menu-item/EditGroupMenuItem.generated';
import { ManageAccessToGroupMenuItem_GroupFragmentDoc } from './contents/manage-access-to-group-menu-item/ManageAccessToGroupMenuItem.generated';
import { CreateConstellationMenuItem_GroupFragmentDoc } from './contents/create-constellation-menu-item/create-constellation-menu-item.generated';
import { RemoveFromGroupMenuItem_GroupFragmentDoc } from './contents/remove-from-group-menu-item/remove-from-group-menu-item.generated';
export type GroupContextMenu_GroupFragment = { id: any, isArchived: boolean, organizationId: any | null, name: string, color: string, parentGroup: { id: any, name: string } | null, icons: { iconDefinition: any } | null, editGroupAccessDialog_group_groupMembersCollection: { edges: Array<{ node: { id: any, groupId: any | null, userId: any | null, user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null, addGroupMemberForm_group_groupMembersCollection: { edges: Array<{ node: { id: any, userId: any | null } }> } | null, organization: { id: any, addGroupMemberForm_group_organizationMembersCollection: { edges: Array<{ node: { user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null } | null };

export const GroupContextMenu_GroupFragmentDoc = `
    fragment GroupContextMenu_Group on Groups {
  id
  isArchived
  ...EditGroupMenuItem_Group
  ...ManageAccessToGroupMenuItem_Group
  ...CreateConstellationMenuItem_Group
  ...RemoveFromGroupMenuItem_Group
}
    ${EditGroupMenuItem_GroupFragmentDoc}
${ManageAccessToGroupMenuItem_GroupFragmentDoc}
${CreateConstellationMenuItem_GroupFragmentDoc}
${RemoveFromGroupMenuItem_GroupFragmentDoc}`;