import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "../constants/breakpoints/breakpoints";
import { Colors } from "../../../common/colors/colors";

const BackgroundAppearAnimation = keyframes({
    from: {
        opacity: 0,
    },
    to: {
        opacity: 0.5,
    }
})

export const DialogBackground = styled.div<{
    show: boolean,
    allowClose: boolean,
}>(({
    show,
    allowClose,
}) => ({
    /* layout */
    position: 'absolute',

    /* size */
    width: '100%',
    height: '100%',

    /* position */
    left: 0,
    top: 0,
    zIndex: -1,

    /* background */
    backgroundColor: 'black',

    /* opacity */
    opacity: show ? 0.5 : 0,

    /* animation */
    transition: 'opacity 400ms ease',
    animation: show ? `${BackgroundAppearAnimation} 400ms ease` : undefined,
    animationFillMode: 'backwards',

    /* interaction */
    cursor: allowClose ? 'pointer' : undefined,
}))

export const DialogPortalInnerContainer = styled.div({
    /* padding */
    paddingTop: '5vh',
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 124,

    /* breakpoints */
    [TABLET_BREAKPOINT]: {
        paddingTop: 24,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    },
})

const DialogAppearDesktopAnimation = keyframes({
    from: {
        transform: `translateY(12px)`,
        opacity: 0,
    },
    to: {
        transform: `translateY(0px)`,
        opacity: 1,
    },
})

const DialogAppearMobileAnimation = keyframes({
    from: {
        transform: `translateY(calc(100vh - 24px))`,
        opacity: 0,
    },
    to: {
        transform: `translateY(0px)`,
        opacity: 1,
    },
})

export const DialogContainer = styled.div<{
    show: boolean,
    windowHeight: number,
    columns: 'one' | 'two',
}>(({
    show,
    windowHeight,
    columns,
}) => ({
    /* layout */
    display: 'grid',
    gridTemplateColumns: columns === 'two' ? '50% 50%' : '100%',
    gridTemplateRows: '550px',
    gridAutoRows: 'max-content',

    /* position */
    position: 'relative',

    /* size */
    width: `calc(100vw - 48px)`,
    maxWidth: columns === 'two' ? 1100 : 550,
    maxHeight: 620,

    /* appearance */
    backgroundColor: Colors.background,
    opacity: show ? 1 : 0,

    /* border */
    border: `1.5px solid rgb(55, 55, 55)`,
    borderRadius: 8,

    /* overflow */
    overflowY: 'hidden',

    /* shadow */
    boxShadow: '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20)',

    /* animation */
    animation: `${DialogAppearDesktopAnimation} 400ms ease`,
    animationFillMode: 'backwards',
    transition: 'transform 400ms ease, opacity 400ms ease, scale 400ms ease',

    /* transform */
    transform: show ? 'scale(1)' : 'scale(0.9)',

    [TABLET_BREAKPOINT]: {
        /* layout */
        gridTemplateColumns: '1fr',
        // gridTemplateRows: columns === 'two' ? 'max-content 1fr max-content' : '1fr max-content',
        gridTemplateRows: '1fr max-content',

        /* size */
        height: windowHeight - 24,
        maxHeight: windowHeight - 24,
        maxWidth: 'unset',

        /* overflow */
        overflowY: 'auto',

        /* border */
        borderWidth: 1,
        borderBottom: 'none',
        borderRadius: 0,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,

        /* animation */
        animation: `${DialogAppearMobileAnimation} 400ms ease`,

        /* transform */
        transform: show ? 'translateY(0px)' : 'translateY(calc(100vh - 24px))',
    },

    [MOBILE_BREAKPOINT]: {
        /* size */
        width: '100vw',
    },
}))

export const CloseButton = styled.div<{
    show: boolean
}>(({
    show,
}) => ({
    /* layout */
    display: show ? 'grid' : 'none',
    justifyContent: 'center',
    alignItems: 'center',

    /* position */
    position: 'absolute',
    right: 12,
    top: 12,

    /* size */
    fontSize: 24,
    width: 36,
    height: 36,

    /* cursor */
    cursor: 'pointer',
}))

export const PortalStyles = css({
    /* layout */
    display: 'grid',
    // gridTemplateColumns: 1100,
    justifyContent: 'center',

    /* position */
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1010,

    /* size */
    width: '100vw',
    height: '100vh',

    /* breakpoints */
    // [CUSTOM_BREAKPOINT(1150)]: {
    //     gridTemplateColumns: 'calc(100vw - 48px)',
    // },

    // [TABLET_BREAKPOINT]: {
    //     gridTemplateColumns: '100vw',
    // }
})

export const BlockInteractionStyles = css({
    pointerEvents: 'none',
})