/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

import { QrCodeImage_NovaFragmentDoc } from '../../../../../novas/qr-code-image/QRCodeImage.generated';
import { ConstellationContextMenu_ConstellationFragmentDoc } from '../../../constellation-context-menu/ConstellationContextMenu.generated';
export type ConstellationGridItem_ConstellationFragment = { id: any, name: string, color: string, isArchived: boolean, organizationId: any | null, icons: { iconDefinition: any } | null, constellationGridItem_constellation_novasCollection: { edges: Array<{ node: { embeddedLink: string | null, novaType: { id: any, code: string } | null, novaDecorationImage: { imageUrl: string | null } | null } }> } | null, constellationContextMenu_constellation_novasCollection: { edges: Array<{ node: { id: any, name: string, embeddedLink: string | null, novaType: { id: any, code: string } | null } }> } | null, EditConstellationModal_Novas: { edges: Array<{ node: { id: any, embeddedLink: string | null, novaType: { code: string } | null, novaDecorationImage: { id: any, imageUrl: string | null } | null } }> } | null };

export const ConstellationGridItem_ConstellationFragmentDoc = `
    fragment ConstellationGridItem_Constellation on Constellations {
  id
  icons {
    iconDefinition
  }
  name
  color
  constellationGridItem_constellation_novasCollection: novasCollection(
    filter: {isDeleted: {eq: false}}
    orderBy: {createdAt: DescNullsLast}
  ) {
    edges {
      node {
        ...QRCodeImage_Nova
        novaType {
          id
          code
        }
      }
    }
  }
  ...ConstellationContextMenu_Constellation
}
    ${QrCodeImage_NovaFragmentDoc}
${ConstellationContextMenu_ConstellationFragmentDoc}`;