/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

import { EditGroupModal_GroupFragmentDoc } from '../../../../modals/edit-group-modal/edit-group-modal.generated';
export type EditGroupMenuItem_GroupFragment = { id: any, name: string, color: string, icons: { iconDefinition: any } | null };

export const EditGroupMenuItem_GroupFragmentDoc = `
    fragment EditGroupMenuItem_Group on Groups {
  id
  ...EditGroupModal_Group
}
    ${EditGroupModal_GroupFragmentDoc}`;