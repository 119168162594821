/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

import { EditConstellationModal_ConstellationFragmentDoc } from '../../../../modals/edit-constellation-modal/edit-constellation-modal.generated';
export type EditConstellationMenuItem_ConstellationFragment = { id: any, name: string, color: string, organizationId: any | null, icons: { iconDefinition: any } | null, EditConstellationModal_Novas: { edges: Array<{ node: { id: any, embeddedLink: string | null, novaType: { code: string } | null, novaDecorationImage: { id: any, imageUrl: string | null } | null } }> } | null };

export const EditConstellationMenuItem_ConstellationFragmentDoc = `
    fragment EditConstellationMenuItem_Constellation on Constellations {
  ...EditConstellationModal_Constellation
}
    ${EditConstellationModal_ConstellationFragmentDoc}`;