/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

export type EditGroupModal_GroupFragment = { id: any, name: string, color: string, icons: { iconDefinition: any } | null };

export const EditGroupModal_GroupFragmentDoc = `
    fragment EditGroupModal_Group on Groups {
  id
  name
  icons {
    iconDefinition
  }
  color
}
    `;