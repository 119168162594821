/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { AddOrganizationMemberButton_OrganizationFragmentDoc } from './subcomponents/add-organization-member-button/AddOrganizationMemberButton.generated';
import { OrganizationMembersList_OrganizationFragmentDoc } from './subcomponents/organization-members-list/OrganizationMembersList.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type OrganizationMembersView_OrganizationQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['UUID'];
}>;


export type OrganizationMembersView_OrganizationQuery = { organizationsCollection: { edges: Array<{ node: { id: any, name: string, organizationMembersList_organization_organizationMembersCollection: { edges: Array<{ node: { id: any, userId: any | null, user: { firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null, organizationMembersList_organization_organizationMemberInvitationsCollection: { edges: Array<{ node: { id: any, invitedUserEmailAddress: string | null, organizationMemberRoleId: any, invitedToOrganizationId: any | null } }> } | null, addUserToOrganizationDialog_organization_organizationMembersCollection: { edges: Array<{ node: { user: { emailAddress: string | null } | null } }> } | null, addUserToOrganizationDialog_organization_organizationMemberInvitationsCollection: { edges: Array<{ node: { invitedUserEmailAddress: string | null } }> } | null } }> } | null };


export const OrganizationMembersView_OrganizationDocument = `
    query OrganizationMembersView_Organization($organizationId: UUID!) {
  organizationsCollection(
    filter: {id: {eq: $organizationId}, isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
        ...AddOrganizationMemberButton_Organization
        ...OrganizationMembersList_Organization
      }
    }
  }
}
    ${AddOrganizationMemberButton_OrganizationFragmentDoc}
${OrganizationMembersList_OrganizationFragmentDoc}`;
export const useOrganizationMembersView_OrganizationQuery = <
      TData = OrganizationMembersView_OrganizationQuery,
      TError = unknown
    >(
      variables: OrganizationMembersView_OrganizationQueryVariables,
      options?: UseQueryOptions<OrganizationMembersView_OrganizationQuery, TError, TData>
    ) =>
    useQuery<OrganizationMembersView_OrganizationQuery, TError, TData>(
      ['OrganizationMembersView_Organization', variables],
      fetcher<OrganizationMembersView_OrganizationQuery, OrganizationMembersView_OrganizationQueryVariables>(OrganizationMembersView_OrganizationDocument, variables),
      options
    );

useOrganizationMembersView_OrganizationQuery.getKey = (variables: OrganizationMembersView_OrganizationQueryVariables) => ['OrganizationMembersView_Organization', variables];
;

export const useInfiniteOrganizationMembersView_OrganizationQuery = <
      TData = OrganizationMembersView_OrganizationQuery,
      TError = unknown
    >(
      pageParamKey: keyof OrganizationMembersView_OrganizationQueryVariables,
      variables: OrganizationMembersView_OrganizationQueryVariables,
      options?: UseInfiniteQueryOptions<OrganizationMembersView_OrganizationQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<OrganizationMembersView_OrganizationQuery, TError, TData>(
      ['OrganizationMembersView_Organization.infinite', variables],
      (metaData) => fetcher<OrganizationMembersView_OrganizationQuery, OrganizationMembersView_OrganizationQueryVariables>(OrganizationMembersView_OrganizationDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteOrganizationMembersView_OrganizationQuery.getKey = (variables: OrganizationMembersView_OrganizationQueryVariables) => ['OrganizationMembersView_Organization.infinite', variables];
;

useOrganizationMembersView_OrganizationQuery.fetcher = (variables: OrganizationMembersView_OrganizationQueryVariables, options?: RequestInit['headers']) => fetcher<OrganizationMembersView_OrganizationQuery, OrganizationMembersView_OrganizationQueryVariables>(OrganizationMembersView_OrganizationDocument, variables, options);