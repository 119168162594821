import { createContext, FC, ReactNode, useState } from "react";

type CreateUseDataProviderProps = {
    children?: ReactNode
}

export const CreateUseDataProvider: FC<CreateUseDataProviderProps> = ({
    children,
}) => {

    // state
    const [cachedData, setCachedData] = useState<Record<string, any>>({})
    
    return (
        <CreateUseDataContext.Provider value={{
            cachedData,
            setCachedData
        }}>
            {children}
        </CreateUseDataContext.Provider>
    )
}

type CreateUseDataContextType = {
    cachedData: Record<string, any>
    setCachedData: React.Dispatch<React.SetStateAction<Record<string, any>>>
}

export const CreateUseDataContext = createContext<CreateUseDataContextType>({
    cachedData: {},
    setCachedData: () => {},
})