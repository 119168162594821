import { red } from '@ant-design/colors'
import { useQueryClient } from '@tanstack/react-query'
import Button from 'antd/es/button'
import message from 'antd/es/message'
import Modal from 'antd/es/modal'
import React, { FC, useCallback, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'
import { Gap, Padding } from '../../../common/constants/constants'
import { queryInvalidationHelper } from '../../../common/react-query/query-invalidation.helper'
import { AppEventContext } from '../../app/providers/app-event-provider/AppEventProvider'
import { service } from './organization-member-remove-user-form.service'
import { OrganizationMemberRemoveMemberForm_OrganizationMemberFragment } from './organization-member-remove-user-form.generated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'

export type OrganizationMemberRemoveMemberFormProps = {
	organizationMember: OrganizationMemberRemoveMemberForm_OrganizationMemberFragment
}

export const OrganizationMemberRemoveMemberForm: FC<OrganizationMemberRemoveMemberFormProps> = ({
	organizationMember,
}) => {

	// styles
	const styles = useStyles()

	// context
	const { logAppEvent } = useContext(AppEventContext)

	// router hooks
	const navigate = useNavigate()

	// query client
	const queryClient = useQueryClient()

	// event handlers
	const handleRemoveUserClicked = useCallback(() => {
		Modal.confirm({
			icon: <FontAwesomeIcon icon={faExclamationCircle} />,
			content: "Are you sure you want to remove this user from this organization?",
			onOk: async () => {
				/* remove the member */
				const result = await service.removeMember(organizationMember.id)

				/* handle any errors that occurred */
				if (!result.success || !result.organizationMember) {
					message.error('Something went wrong. Please try again.')
					return;
				}
				
				/* invalidate relevant queries */
				queryClient.invalidateQueries(
					queryInvalidationHelper([
						{ organizationMemberId: result.organizationMember.id },
						{ organizationId: result.organizationMember.organizationId },
					])
				)

				/* log an app event */
				await logAppEvent('removed_user_from_organization', {
					organizationMemberId: organizationMember.id,
				})

				/* inform the user that the request succeeded and navigate way */
				message.success('Successfully removed user from Enterprise');
				navigate('..')
			},
			okType: 'danger',
			okText: 'Yes, I\'m sure',
		})
	}, [organizationMember.id, queryClient, logAppEvent, navigate])

	return (
		<div className={styles.enterpriseUserRemoveUserForm}>
			<div className={`${styles.container}`}>
				<h3>If you do this, the user won't be able to access this organization anymore.</h3>
				<Button style={{ backgroundColor: red.primary }} onClick={handleRemoveUserClicked}>
					Remove User from Organization
				</Button>
			</div>
		</div>
	)
}

const useStyles = createUseStyles({
	enterpriseUserRemoveUserForm: {
		display: 'grid',
		textAlign: 'center',
		gap: Gap.default,
	},
	container: {
		display: 'grid',
		textAlign: 'center',
		gap: Gap.default,
	},
	disabled: {
		opacity: 0.5,
	},
	warningContainer: {
		display: 'grid',
		justifyContent: 'center',
		marginBottom: Padding.most,
	},
})