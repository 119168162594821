import Modal from 'antd/es/modal'
import Spin from 'antd/es/spin'
import Form from 'antd/es/form'
import Select from 'antd/es/select'
import message from 'antd/es/message'
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { useQueryClient } from '@tanstack/react-query'
import { AppEventContext } from '../../app/providers/app-event-provider/AppEventProvider'
import { queryInvalidationHelper } from '../../../common/react-query/query-invalidation.helper'
import { service } from './edit-organization-member-invitation-modal.service'
import { EditOrganizationMemberInvitationModal_OrganizationMemberInvitationFragment } from './edit-organization-member-invitation-modal.service.generated'
import { TypesContext } from '../../app/providers/types-provider/TypesProvider'

type FormValues = {
	organizationMemberRoleId: string
}

type EditOrganizationMemberInvitationModalProps = {
	organizationMemberInvitation: EditOrganizationMemberInvitationModal_OrganizationMemberInvitationFragment
	open: boolean
	onDismiss?: () => void
}

export const EditOrganizationMemberInvitationModal: FC<EditOrganizationMemberInvitationModalProps> = ({
	organizationMemberInvitation,
open,
	onDismiss,
}) => {

	// styles
	const styles = useStyles()

	// context
	const { logAppEvent } = useContext(AppEventContext)
	const { organizationMemberRoles } = useContext(TypesContext)

	// state
	const [loading, setLoading] = useState<boolean>(false)

	// form state
	const {
		control,
		handleSubmit,
		reset,
		getValues,
		formState: { isValid: formValid, isDirty: formDirty },
	} = useForm({
		mode: 'all',
		defaultValues: {
			organizationMemberRoleId: organizationMemberInvitation.organizationMemberRoleId,
		} as FormValues,
	})

	// query client
	const queryClient = useQueryClient()

	// side effects
	useEffect(() => {
		if (open) {
			reset({
				organizationMemberRoleId: organizationMemberInvitation.organizationMemberRoleId,
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	// event handlers
	const handleDismiss = useCallback(() => {
		setLoading(false)
		onDismiss && onDismiss()
	}, [onDismiss])

	const handleFormSubmit = useCallback(async () => {
		setLoading(true)

		/* retrieve form values */
		const { organizationMemberRoleId } = getValues()

		/* update the invitation */
		const result = await service.updateInvitation(
			organizationMemberInvitation.id,
			{
				organizationMemberRoleId,
			},
		)

		/* handle any errors that occurred */
		if (!result.success || !result.organizationMemberInvitation) {
			message.error('Something went wrong. Please try again.')
			setLoading(false)
			return;
		}

		/* invalidate relevant queries */
		queryClient.invalidateQueries(
			queryInvalidationHelper([
				{ organizationId: result.organizationMemberInvitation.invitedToOrganizationId },
			])
		)

		/* log an app event */
		await logAppEvent('edited_organization_member_invitation', {
			organizationMemberInvitationId: organizationMemberInvitation.id,
		})

		/* inform the user that the request succeeded */
		message.success(`Successfully updated invitation`)

		/* end the loading state and dismiss the modal */
		setLoading(false)
		handleDismiss()
	}, [getValues, organizationMemberInvitation.id, queryClient, logAppEvent, handleDismiss])

	const handleOk = useCallback(() => {
		handleSubmit(handleFormSubmit)()
	}, [handleFormSubmit, handleSubmit])

	const handleCancel = useCallback(() => {
		handleDismiss()
	}, [handleDismiss])

	return (
		<Modal title="Edit Invitation" open={open} onOk={handleOk} onCancel={handleCancel} okText="Update Invitation" okButtonProps={{ disabled: !formValid || !formDirty }}>
			<div className={styles.innerContainer}>
				<Spin spinning={loading} tip="Updating Invitation...">
					<Form
						layout="vertical"
						onSubmitCapture={handleSubmit(handleFormSubmit)}
					>
						<Form.Item label="User Group">
							<Controller
								control={control}
								name="organizationMemberRoleId"
								rules={{ required: true }}
								render={({ field }) => (
									<Select {...field}>
										{organizationMemberRoles?.map(organizationMemberRole => (
											<Select.Option
												key={organizationMemberRole.id}
												value={organizationMemberRole.id}
											>
												{organizationMemberRole.name}
											</Select.Option>
										))}
									</Select>
								)}
							/>
						</Form.Item>
					</Form>
				</Spin>
			</div>
		</Modal>
	)
}

const useStyles = createUseStyles({
	editOrganizationMemberInvitationModal: {
		display: 'grid'
	},
	innerContainer: {
		maxHeight: 600,
		overflowY: 'auto',
	},
})