import { FC } from "react";
import { MobileTopBarContainer } from "./preferences-mobile-top-bar.styled";
import Button from 'antd/es/button';
import { MobileNavMenuButton } from "./subcomponents/mobile-nav-menu-button/mobile-nav-menu-button";
import { MobileLogo } from "../../../../../../../app/components/app-shell/subcomponents/app-header/subcomponents/mobile-logo/mobile-logo";
import { NotificationsButton } from "../../../../../../../app/components/app-shell/subcomponents/app-header/subcomponents/notifications-button/NotificationsButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/pro-regular-svg-icons/faQuestion";

export const PreferencesMobileTopBar: FC = () => {
    return (
        <MobileTopBarContainer>
            <MobileLogo />
            <MobileNavMenuButton />
            <NotificationsButton />
            <Button type="default" icon={<FontAwesomeIcon icon={faQuestion} />} />
        </MobileTopBarContainer>
    )
}