/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { QrCodeImage_NovaFragmentDoc } from '../../../novas/qr-code-image/QRCodeImage.generated';
export type EditConstellationModal_ConstellationFragment = { id: any, name: string, color: string, organizationId: any | null, icons: { iconDefinition: any } | null, EditConstellationModal_Novas: { edges: Array<{ node: { id: any, embeddedLink: string | null, novaType: { code: string } | null, novaDecorationImage: { id: any, imageUrl: string | null } | null } }> } | null };

export const EditConstellationModal_ConstellationFragmentDoc = `
    fragment EditConstellationModal_Constellation on Constellations {
  id
  name
  color
  icons {
    iconDefinition
  }
  organizationId
  EditConstellationModal_Novas: novasCollection(
    orderBy: {createdAt: DescNullsLast}
  ) {
    edges {
      node {
        ...QRCodeImage_Nova
        id
        novaType {
          code
        }
        novaDecorationImage {
          id
          imageUrl
        }
      }
    }
  }
}
    ${QrCodeImage_NovaFragmentDoc}`;